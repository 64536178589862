import { Text } from '@chakra-ui/react';
import { CardPageContainer } from '@cksoftware/react-base';

export const Home = () => {
  return (
    <CardPageContainer header={'Welcome'}>
      <Text>Welcome to Element Compounding Pharmacy.</Text>
    </CardPageContainer>
  );
};
