export const ApplicationRoutes = {
  account: {
    myProfile: '/my-profile',
    login: '/login',
    logout: '/logout',
    resetPassword: '/reset-password',
    confirmEmail: '/confirm-email',
    forgotPassword: '/forgot-password'
  },
  admin: {
    users: '/admin/users'
  },
  leads: {
    manageLeads: '/leads/manage-leads'
  }
};
